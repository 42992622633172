import { useState } from "react";
import App from "./App/App";
import FontTest from "./FontTest";

export default function PageSelector() {
  const pages = [
    {
      name: "App",
      component: <App />,
    },
    {
      name: "FontTest",
      component: <FontTest />,
    },
  ];

  const [page, setPage] = useState(null);

  // if page is null, render a div with a button for each page in the pages array, when a button is clicked, set the page state to the name of the page
  function RenderPage() {
    if (page === null) {
      return (
        <div className="m-5">
          <h1 className="ml-3 mt-10 font-bold text-xl">Choose a page:</h1>
          {pages.map((page) => {
            return (
              <button
                className="m-3 bg-gray-200 px-3 py-2 rounded-lg border-gray-300 border-2"
                onClick={() => {
                  setPage(page.name);
                }}
                key={page.name}
              >
                {page.name}
              </button>
            );
          })}
        </div>
      );
    }

    // if page is not null, render the component of the page in the pages array that has the same name as the page state
    return pages.find((p) => p.name === page).component;
  }

  return (
    <div>
      <RenderPage />
    </div>
  );
}
