import HeaderBar from "./HeaderBar/HeaderBar";
import BodyContent from "./BodyContent/BodyContent";

function App() {
  return (
    <div className="w-screen h-screen bg-template bg-cover">
      {/* <HeaderBar />
      <BodyContent /> */}
    </div>
  );
}

export default App;
