import "./index.css";

function FontTest() {
  const fonts = [
    "Teko",
    "IBM_Plex_Mono",
    "Alfa_Slab_One",
    "Alex_Brush",
    "BenchNine",
    "Homemade_Apple",
    "Lexend_Exa",
  ];

  // return a div with an h1 tag for each font in the fonts array, with the className name of the font
  function RenderFonts({ fonts, classNames }) {
    return (
      <div>
        {fonts.map((font) => {
          const className = "font-" + font + " " + classNames;
          return (
            <h1 className={className} key={font}>
              This text is in {font} size 1.60 rem
            </h1>
          );
        })}
      </div>
    );
  }

  function RenderFontsAa({ fonts, classNames }) {
    return (
      <div>
        {fonts.map((font) => {
          const className = "font-" + font + " " + classNames;
          return (
            <h1 className={className} key={font}>
              Aa
            </h1>
          );
        })}
      </div>
    );
  }

  return (
    <div className="App">
      {/* <header className="App-header">
        <p className="h-10 bg-green-100 text-pink-500 text-9xl mb-40">
          Hello world!
        </p>
      </header> */}
      <RenderFonts fonts={fonts} classNames="text-1.60-rem m-5 bg-red-100" />
      <RenderFonts
        fonts={fonts}
        classNames="text-1.60-rem w-48 h-20 overflow-hidden m-5 bg-orange-100"
      />
      <RenderFonts
        fonts={fonts}
        classNames="text-1.60-rem w-48 h-20 overflow-hidden m-5 bg-yellow-100"
      />
      <RenderFontsAa
        fonts={fonts}
        classNames="text-3.20-rem w-40 h-40 leading-40 overflow-hidden m-5 bg-green-100 text-center"
      />
      <RenderFontsAa
        fonts={fonts}
        classNames="text-8.00-rem w-40 h-40 leading-40 overflow-hidden m-5 bg-green-100 text-center"
      />
      {/* Necessary for fonts to load due to mapping <div>
        <h1 className="font-IBM_Plex_Mono"></h1>
        <h1 className="font-Teko"></h1>
        <h1 className="font-Alfa_Slab_One"></h1>
        <h1 className="font-Alex_Brush"></h1>
        <h1 className="font-BenchNine"></h1>
        <h1 className="font-Homemade_Apple"></h1>
        <h1 className="font-Lexend_Exa"></h1>
      </div> */}
    </div>
  );
}

export default FontTest;
